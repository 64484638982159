<template>
  <v-container>
    <v-row cols="12" class="ma-5">
      <v-col class="ma-2">
        <v-row justify="center">
          <v-card :loading="loading" class="mx-auto" max-width="374">
            


            <v-card-text>
              <v-date-picker
                v-model="fecha"
                locale="es-cn"
                :allowed-dates="allowedDates"
                first-day-of-week="1"
                full-width
                @change="getHorarios"
              >
              </v-date-picker>
            </v-card-text>

            <v-divider class="mx-4"></v-divider>
            <v-card-title>Categorias</v-card-title>

            <v-card-text>
              <v-chip-group
                v-model="opcionCategoria"
                active-class="primary white--text"
                mandatory
                column
              >
                <div
                  v-for="categoria in categorias"
                  :key="categoria.id"
                  :value="categoria"
                  @click="getServicios"
                >
                  <v-chip :value="categoria.id">
                    {{ categoria.titulo }}
                  </v-chip>
                </div>
              </v-chip-group>
            </v-card-text>

            <v-divider class="mx-4"></v-divider>

            <v-card-title>Tipo de Servicio</v-card-title>

            <v-card-text>
              <v-chip-group
                v-model="opcionServicio"
                active-class="primary white--text"
                mandatory
                column
              >
                <div
                  v-for="servicio in servicios"
                  :key="servicio.id"
                  :value="servicio"
                  @click="getHorarios"
                >
                  <v-chip :value="servicio">
                    {{servicio.titulo}} - ${{servicio.precio}}
                  </v-chip>
                </div>
              </v-chip-group>
            </v-card-text>

            <v-divider class="mx-4"></v-divider>

            <v-card-title>Horarios disponibles</v-card-title>
            
            <v-card-text v-if="horarios.length!=0">
              <v-chip-group
                v-model="opcionHorario"
                active-class="primary white--text"
                mandatory
                column
              >
                <v-chip
                  v-for="horario in horarios"
                  :key="horario.id"
                  :value="horario"
                >
                  {{ horario.inicio }}
                </v-chip>

              </v-chip-group>
            </v-card-text>

           <v-card-text v-else>
              <h4>Consultar otros horarios vía WhatsApp (Enlace Whatsaapp)</h4>
            </v-card-text>

            <v-divider class="mx-4"></v-divider>

            <v-card-title>Tipo de pago</v-card-title>

            <v-card-text>
              <v-chip-group
                v-model="opcionPago"
                active-class="primary white--text"
                mandatory
                column
              >
                <v-chip
                  :value="1"
                >
                  Pago online
                </v-chip>
                <v-chip
                  :value="2"
                >
                  Tranferencia
                </v-chip>
                <v-chip
                  :value="3"
                >
                  GiftCard 
                </v-chip>
              </v-chip-group >

              <div class="text-center">
                <v-dialog
                  v-model="dialog"
                  width="500"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="blue"
                      dark
                      v-bind="attrs"
                      v-on="on"
                    >
                      Datos tranferencia
                    </v-btn>
                  </template>

                  <v-card>
                    <v-card-title class="text-h5 grey lighten-2">
                      Datos tranferencia
                    </v-card-title>

                    <v-card-text>
                      Banco BCI, (banco crédito e inversiones)
                      Cuenta corriente: 81935773
                      Nombre: Jaime Brisso
                      Rut: 9.379.339-0
                      Correo: TERAPIA.RESERVAS@GMAIL.COM                    
                      
                      </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="dialog = false"
          >
            I accept
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
              
          </v-card-text>
            <v-divider class="mx-4"></v-divider>

            <v-card-title>Ingrese datos de pago</v-card-title>

            <v-card-text>
              <v-form v-model="valid">
                <v-container>
                  <v-text-field
                    v-model="nombre"
                    :rules="nameRules"
                    label="Nombre"
                    required
                  ></v-text-field>

                  <v-text-field
                    v-model="email"
                    :rules="emailRules"
                    label="E-mail"
                    required
                  ></v-text-field>

                  <v-text-field
                    v-model="telefono"
                    :rules="phoneRules"
                    label="Telefono"
                    required
                  ></v-text-field>
                  <v-text-field
                    v-model="nota"
                    label="Nota"
                    required
                  ></v-text-field>
                </v-container>
              </v-form>
            </v-card-text>
            <div v-if="opcionPago==3">
            <v-divider class="mx-4"></v-divider>

            <v-card-title v-if="opcionPago==3">Ingrese los datos de la gifCard</v-card-title>

            <v-card-text>
              <v-form v-model="valid">
                <v-container>
                  <v-text-field
                    v-model="nombreGif"
                    :rules="nameRules"
                    label="Nombre"
                    required
                  ></v-text-field>

                  <v-text-field
                    v-model="emailGif"
                    :rules="emailRules"
                    label="E-mail"
                    required
                  ></v-text-field>

                  <v-text-field
                    v-model="telefonoGif"
                    :rules="phoneRules"
                    label="Telefono"
                    required
                  ></v-text-field>
                  <v-text-field
                    v-model="notaGif"
                    label="Nota"
                    required
                  ></v-text-field>
                </v-container>
              </v-form>
            </v-card-text>
            </div>
            <v-divider class="mx-4"></v-divider>

            <v-card-title>Resumen</v-card-title>

            <v-card-text>
              <div class="mb-2">
                <div class="font-weight-black">{{ opcionServicio.titulo }}</div>
             
              <div>{{ opcionServicio.descripcion }}</div>

              </div>
              
              <v-divider></v-divider>
             
              <v-list>
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon> mdi-cash-multiple </v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title>
                      Precio: $ {{ opcionServicio.precio }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item v-if="opcionPago==2">
                  <v-list-item-icon>
                    <v-icon> mdi-cash-multiple </v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title>
                      Abono: $ {{ opcionServicio.abono }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon> mdi-calendar </v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title>
                      {{ fecha }} - {{ opcionHorario.inicio }} -
                {{ opcionHorario.termino }}
                    </v-list-item-title>

                  </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon> mdi-account </v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title>{{ nombre }}</v-list-item-title>

                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon> mdi-phone </v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title>{{ telefono }}</v-list-item-title>

                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon> mdi-email </v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title>{{ email }}</v-list-item-title>

                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card-text>

            <v-card-actions>
              <v-btn color="primary" text @click="postReserva" block>
                Reservar
              </v-btn>         
            </v-card-actions>
          </v-card>
        </v-row>
      </v-col>
    </v-row>


        <v-dialog
        v-model="showModal"
        transition="dialog-top-transition"
        max-width="600"
        persistent
      >
        <template v-slot:default="dialog">
          <v-card>
            <v-toolbar
              color="primary"
              dark
            >Reserva realizada</v-toolbar>
            <v-card-text>
              <div class="text-h6 pa-12">Se ha realizado la reserva</div>

              <form method="get" :action=pago.url>
                <input type="hidden" name="token_ws"  :value=pago.token />

                <v-btn type="submit">Ir a pagar</v-btn>
              </form>
            </v-card-text>
            <v-card-actions class="justify-end">
            
            </v-card-actions>
          </v-card>
        </template>
      </v-dialog>

    <v-dialog
      v-model="dialogEspera"
      hide-overlay
      persistent
      width="300"
    >
      <v-card
        color="primary"
        dark
      >
        <v-card-text>
          Procesando la reserva
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>

  </v-container>
</template>

<script>
import Swal from 'sweetalert2';

export default {
  
  data: () => ({
    pago: {
      "url": "",
      "token": "",
    },
    dialog:false,
    dialogEspera:false,
    nombre: "",
    email: "",
    nota: "",
    telefono: "",
    nombreGif: "",
    emailGif: "",
    notaGif: "",
    telefonoGif: "",
    categoriaAux:[],
    dias:[],
    bandera: false,
    fechasDisponibles: ["28/02/2022"],
    detalle: "",
    showModal: false,
    opcionPago: 1,
    serviciosCategorias: [],
    horarios: [],
    categorias: [],
    servicios: [],
    opcionCategoria: 1,
    opcionServicio: 1,
    opcionHorario: 1,
    reservas: [],
    fechahoy:new Date(Date.now())
      .toISOString()
      .substr(0, 10) ,
    fecha: new Date(Date.now())
      .toISOString()
      .substr(0, 10),
    loading: false,
    servicios: [
    
      {
        hora: "Frozen Yogurt",
        calories: 159,
        fat: 6.0,
      },
    ],
    valid: false,
    nombre: "",
    telefono: "",
    nameRules: [(v) => !!v || "Nombre es requerido"],
    phoneRules: [(v) => !!v || "Telefono es requerido"],
    email: "",
    emailRules: [
      (v) => !!v || "Correo es requerido",
      (v) => /.+@.+/.test(v) || "Correo no valido",
    ],
  }),
  created() {
    this.getCategorias();
    this.getServicios();
    this.listaDias();
    this.getHorarios();
  },
  methods: {
    recargarHorario(){
      this.horarios = this.horarios
    },
    rangoFecha(inicio){
      if(this.opcionServicio.horainicio<inicio){
        return true
      }else{
        return false
      }
    },
    allowedDates(val) {
      for (var i = 0; i < this.dias.length; i++) {
         if (this.dias[i].fecha == val){
            return val
         } 
      }
    },
    async listaDias() {
      try {
        const response = await this.axios.get("/horario/listadias");
        this.dias = response.data;
      } catch (error) {
        console.log(error);
      }
    },
    
    reserve() {
      this.loading = true;
      setTimeout(() => (this.loading = false), 2000);
    },
    async getReservas() {
      try {
        const response = await this.axios.get("/reserva");
        this.reservas = response.data;
 
      } catch (error) {
        console.log(error);
      }
    },
    async postReserva() {
      this.dialogEspera = true
      try {
        var valor = 0
        if (this.opcionPago==1 || this.opcionPago==3){
          valor = this.opcionServicio.precio
        }else{
          valor = this.opcionServicio.abono
        }
        const response = await this.axios.post("/reserva", {
          nombre: this.nombre,
          telefono: this.telefono,
          email: this.email,
          fecha: this.fecha,
          inicio: this.opcionHorario.inicio,
          termino: "a",
          idCategoria: this.opcionCategoria,
          idHorario: this.opcionHorario.id,
          idServicio: this.opcionServicio.id,
          idTiporeserva: this.opcionPago,
          idEstadoreserva: 1,
          precio: this.opcionServicio.precio,
          estado: 1,
          nota: this.nota,
          abono: valor,
          nombreGif: this.nombreGif,
          telefonoGif: this.telefonoGif,
          emailGif: this.emailGif,
          notaGif: this.notaGif,
        });
        this.dialogEspera = false
        if( response.data.token != null){
          this.pago = response.data;
          if(this.opcionPago==1 || this.opcionPago==3){
            this.showModal = true
          }else{
            Swal.fire({
            title: "Reserva realizada",
            text: "Se ha realizado la reserva",
            icon: "success",
            confirmButtonText: "Ok",
          });
          }
        }else{
           Swal.fire({
          icon: "error",
          title: "Oops...",
          text: response.data,
        });
          
        }

        this.reservas = response.data;
        this.detalle = this.reservas
      } catch (error) {
       Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "No se pudo realizar la reserva",
        });
      }
    },
    async getCategorias() {
      try {
        const response = await this.axios.get("/categoria");
        this.categoriaAux=[]
        for (let index = 0; index < response.data.length; index++) {
          const element = response.data[index];
          let categoria = {
            id: element.id,
            titulo: element.titulo,
            descripcion: element.descripcion,
          };
          if(categoria.id!=3 && categoria.id!=5){
            this.categoriaAux.push(categoria);
          }
        }
        this.categorias = this.categoriaAux;
      } catch (error) {
        console.log(error);
      }
    },

    async getServicios() {
      try {
        this.getHorarios();
        const response = await this.axios.get("/serviciocategoria/servicioporcategoria/"+this.opcionCategoria);
        this.servicios = response.data;
      } catch (error) {
        console.log(error);
      }
    },
    async getServiciosCategorias() {
      try {
        const response = await this.axios.get("/serviciocategoria");
        this.serviciosCategorias = response.data;

      } catch (error) {
        console.log(error);
      }
    },
    async getHorarios() {
      try {
        const response = await this.axios.post("/horario/horariodeldia",{
          "fecha": this.fecha,
          "categoria": this.opcionCategoria,
          "servicio": this.opcionServicio,
        });
        this.horarios = response.data;
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
